<template>
  <div class="page" style="box-sizing: border-box; margin-top: 46px;">
    <van-nav-bar title="角色权限" fixed left-arrow @click-left="$router.back()" right-text="+角色" @click-right="showAddRole = true"></van-nav-bar>
    <van-tree-select
        height="calc(100% - 50px)"
        :items="items"
        :active-id.sync="activeIds"
        :main-active-index.sync="activeIndex"
        @click-nav="clickRole"
    />

    <div style="position: absolute;env(safe-area-inset-bottom); z-index: 100;width: 100%;display: flex;justify-content: center">
      <van-button round type="primary" :disabled="!enableSave" @click="clickSave">保存修改</van-button>
    </div>

    <van-dialog v-model="showAddRole" title="添加角色" show-cancel-button :before-close="validAddRole">
      <van-form style="padding: 16px">
        <van-field type="text" v-model="addRole.code" label="角色编码" required placeholder="任意字符"></van-field>
        <van-field type="text" v-model="addRole.name" label="角色名称" required placeholder="见名知意"></van-field>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: "rolePermission",
  data() {
    return {
      items: [],
      activeIds: [],
      activeIndex: 0,
      roleList: [],
      permissionList: [],
      rolePermissionList: [],
      showAddRole: false,
      addRole: {code:'', name:''}
    };
  },
  created() {
    this.initData()
  },
  computed: {
    enableSave() {
      if (this.roleList.length > 0 && this.roleList[this.activeIndex].code === 'admin') return false
      let old = this.rolePermissionList.map(it => it.id).sort().join()
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      let changed = this.activeIds.sort().join()
      return old !== changed
    }
  },
  methods: {
    validAddRole(action, done) {
      if (action === 'cancel') {
        done()
        return
      }
      if (this.addRole.code === '' || this.addRole.name === '') {
        this.$toast.fail('请输入编码和名称')
        done(false)
      } else {
        this.$http.post('role', this.addRole).then(res => {
          if (res.code === 200) {
            this.$toast.success('添加成功')
            done()
          } else {
            done(false)
            this.$toast.fail(res.msg)
          }
        }).catch(err => {
          done(false)
          this.$toast.fail(err.toLocaleString())
        })
      }
    },

    async loadAllRoles() {
      let res = await this.$http.get('role')
      if (res.code === 200) {
        this.roleList = res.data
      }
    },
    async loadAllPermissions() {
      let res = await this.$http.get('permission')
      if (res.code === 200) {
        this.permissionList = res.data
      }
    },
    initData() {
      let load = this.$toast.loading()
      Promise.all([this.loadAllRoles(), this.loadAllPermissions()]).then(() => {
        this.loadRolePermission(this.roleList[0])
        load.clear()
        let items = []
        this.roleList.forEach(role => {
          let item = {id: role.id, text: role.name}
          item.children = this.permissionList.map(p => { return {id: p.id, text: p.name} })
          items.push(item)
        })
        this.items = items
      })
    },
    clickRole(index) {
      console.log(index)
      this.loadRolePermission(this.roleList[index])
    },
    async clickSave() {
      let res = await this.$http.put('rolePermission', {roleId: this.roleList[this.activeIndex].id, permissionList: this.activeIds})
      if (res.code === 200) {
        this.$toast.success('更新成功')
        await this.loadRolePermission(this.roleList[this.activeIndex])
      } else {
        this.$toast.fail(res.msg)
      }
    },

    async loadRolePermission(role) {
      let data
      if (role.code === 'admin') {
        data = this.permissionList
      } else {
        let res = await this.$http.get('rolePermission', {params: {roleId: role.id}})
        if (res.code === 200) {
          data = res.data
        }
      }
      this.rolePermissionList = data
      this.activeIds = data.map(it => it.id)
    }
  }
}
</script>

<style scoped>
/deep/ .van-tree-select__item:last-child {
  margin-bottom: 100px;
}
</style>
